<template>
    <div class="page1">
        <EForm :formColumns="formColumns"
               :rowSize="4"
               :optionsBtn="true"
               :actionBtn="false"
               :formData="searchForm"
               ref="form"
               @onSearch="onSearch"
               :searchFlag="true"
               @getChange="changeSelect"
               :exportData="searchForm"
               :exportData1="searchForm"
               url="contractExport"
               url1="contractInfoExport"
               :exportShow1="true"
               fileName="合同列表"
               fileName1="合同清单统计"
              >
        </EForm>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current"
                :page_size="searchForm.size"

        >
            <el-table-column fixed="right" label="操作" width="180px">
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="goTo('ContractUpdate',{contractId:scope.row.contractId})"
                        :disabled="scope.row.status==2">
                            变更
                        </EButton>
                        <EButton type="text" @click="goTo('ContractDetail',{contractId:scope.row.contractId})">
                            详情
                        </EButton>
                        <EButton type="text" @click="goTo('RefundRent',{contractId:scope.row.contractId})"
                                 :disabled="scope.row.status==2">
                            退租
                        </EButton>
                        <el-button type="text" @click="goTo('ContractLicenceAdd',{contractId:scope.row.contractId,boothCode:scope.row.boothCode})">
                            添加证照
                        </el-button>
                        <el-button type="text" @click="goTo('ReSignContract',{contractId:scope.row.contractId})">
                            续签
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="dialogForm.boothInformationId?'修改摊位':'新增摊位'"
                 @handleClose="cancelDialog" width="50%" @handleClick="saveData(dialogForm.boothInformationId)"
                 :disabled="saveDisabled">
            <el-row :gutter="10">
                <el-form size="small" label-position="'right'" label-width="120px" :model="dialogForm"
                         :rules="formRules" ref="form">
                    <el-col :span="12">
                        <el-form-item label="摊位编号：" prop="boothCode">
                            <el-input v-model="dialogForm.boothCode" placeholder="摊位类型"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="面积（m²）：" prop="areas">
                            <el-input v-model="dialogForm.areas" placeholder="摊位类型"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="区域：" prop="areaId">
                            <el-select placeholder="请选择" v-model="dialogForm.areaId" class="ws">
                                <el-option v-for="item in areaOptions" :key="item.value" :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="摊位用途：" prop="boothUseId">
                            <el-select placeholder="请选择" v-model="dialogForm.boothUseId" class="ws">
                                <el-option v-for="item in boothUseOptions" :key="item.value" :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="位置：" prop="placeId">
                            <el-select placeholder="请选择" v-model="dialogForm.placeId" class="ws">
                                <el-option v-for="item in locationOptions" :key="item.value" :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="摊位类型：" prop="boothTypeId">
                            <el-select placeholder="请选择" v-model="dialogForm.boothTypeId" class="ws">
                                <el-option v-for="item in boothTypeOptions" :key="item.value" :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="说明：" prop="remark">
                            <el-input type="textarea" v-model="dialogForm.remark" placeholder="说明"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";

  export default {
    name: 'Login',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '合同编号',
            prop: 'contractCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName'
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '面积',
            prop: 'areas'
          },
          {
            label: '商户类型',
            prop: 'tenantTypeCn'
          },
          {
            label: '主营商品类型',
            prop: 'mainWareTypeName'
          },
          {
            label: '负责人',
            prop: 'signPerson'
          },
          {
            label: '身份证号',
            prop: 'idCard'
          },
          {
            label: '联系电话',
            prop: 'tenantPhone'
          },
          {
            label: '统一社会信用代码',
            prop: 'socialCreditCode',
          },
          {
            label: '营业执照地址',
            prop: 'businessLicenseAdress',
          },
          {
            label: '开户行及其账号',
            prop: 'bankAccount',
          },
          {
            label: '合同签订人',
            prop: 'createUserCn'
          },
          {
            label: '合同签订时间',
            prop: 'createTime'
          },
          {
            label: '合同生效日期',
            prop: 'inDate'
          },
          {
            label: '合同到期日期',
            prop: 'expireDate'
          },
          {
            label: '合同状态',
            prop: 'statusCn'
          },
          {
            label: '商户录入时间',
            prop: 'tenantCreateTime'
          },
          {
            label: '续签时间',
            prop: 'tenantUpdateTime',
          },
        ],
        formColumns: [
          {
            title: '摊位号',
            type: 'text',
            property: 'boothCode',
            show: true
          },
          {
            title: '负责人 ',
            type: 'text',
            property: 'signPerson',
            show: true
          },
          {
            title: '合同编号',
            type: 'text',
            property: 'contractCode',
            show: true
          },
          {
            title: '合同签订开始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: []
          },
          {
            title: '商户类型',
            type: 'select',
            property: 'tenantType',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '个体',
                value: 1,
              },
              {
                label: '公司',
                value: 2,
              },
              {
                label: '其他',
                value: 3,
              }
            ]
          },
          {
            title: '主营商品类型',
            type: 'text',
            property: 'mainWareTypeName',
            show: true
          },
          {
            title: '合同状态',
            type: 'select',
            property: 'status',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '执行中',
                value: 1,
              },
              {
                label: '已退租',
                value: 2,
              },
              {
                label: '已续签',
                value: 3,
              }
            ]
          },
          {
            title: '合同签订截止时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: []
          },
          {
            title: '身份证号 ',
            type: 'text',
            property: 'idCard',
            show: true
          },
          {
            title: '合同签订人',
            type: 'text',
            property: 'createUser',
            show: true
          },
          {
            title: '证照名称',
            type: 'text',
            property: 'certificateName',
            show: true
          },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          signPerson: null,
          contractCode: null,
          createTimeStart: null,
          areaId: null,
          tenantType: null,
          status: null,
          createTimeEnd: null,
          placeId: null,
          idCard: null,
          createUser: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formRules: {
          boothCode: vxRule(true, '', "blur", "摊位编号不能为空"),
          areas: vxRule(true, '', "blur", "面积不能为空"),
          areaId: vxRule(true, '', "change", "区域不能为空"),
          boothUseId: vxRule(true, '', "change", "摊位用途不能为空"),
          placeId: vxRule(true, '', "change", "位置不能为空"),
          boothTypeId: vxRule(true, '', "change", "摊位类型不能为空"),
          remark: vxRule(true, '', "blur", "说明不能为空"),
        },
        dialogForm: {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        },
        options: [],
        form: {},
        areaOptions: [],
        locationOptions: [],
        boothUseOptions: [],
        boothTypeOptions: [],
      }
    },
    watch: {},
    created() {
      this.getData()
      this.getAreaLabel()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      changeSelect(prop){
        if(prop=='areaId'){
          this.getPlaceLabel()
        }
      },
      saveData(boothInformationId) {
        this.$refs['form'].validate((valid, object) => {
          if (valid) {
            this.boothInfoSubmit(boothInformationId)
          } else {
            return false;
          }
        })
      },
      onSearch() {
        this.searchForm.current = 1
        this.getData()
      },
      changePage(current) {
        this.searchForm.current = current
        this.getData()
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm = {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        }
        this.$refs.form.resetFields();
      },
      async getData() {
        let res = await Http.cmcontractmanage(this.searchForm)
        if (res.code == 200) {
          this.tableData = res.data.records
          this.count = res.data.total
          this.$nextTick(() => {
            this.tableData.forEach((item) => {
              if(item.status!=3){
                item.tenantUpdateTime = ""
              }
            });
          });
        }
      },
      //新增或者修改请求
      async boothInfoSubmit(boothInformationId) {
        this.setDisabled(true)
        let params = {
          boothCode: this.dialogForm.boothCode,
          areas: this.dialogForm.areas,
          areaId: this.dialogForm.areaId,
          boothUseId: this.dialogForm.boothUseId,
          placeId: this.dialogForm.placeId,
          boothTypeId: this.dialogForm.boothTypeId,
          remark: this.dialogForm.remark,
        }
        if (boothInformationId) {
          params.boothInformationId = boothInformationId
        }
        let res = await Http.boothInfoSubmit(params)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dialogVisible = false
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },

      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaId:this.searchForm.areaId,
          status:null
        })
        if (res.code == 200) {
          this.formColumns[9].options = res.data
          this.locationOptions = res.data
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({
          status:null
        })
        if (res.code == 200) {
          this.formColumns[4].options = res.data
          this.areaOptions = res.data
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }
</style>
